import Module from "./base/module";
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default class Gallery extends Module {
    run() {
        this.listeners();
    }

    init() {
        this.data.maxFileSizeMB = 7;
        this.data.images = {};
        this.data.tiu_images = {};
        this.data.photoSwipeInstance = false;
        this.data.photoSwipeOptions = {
            index: 0,
            showAnimationDuration: 0,
            hideAnimationDuration: 0,
            closeEl: true,
            captionEl: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            counterEl: true,
            arrowEl: true,
            preloaderEl: true,
            closeOnScroll: false,
        };

        this.initSelectors();
        this.initLabels();
        this.initDraggableGalleries();
    }

    initSelectors() {
        this.selectors.imageItemTrigger = ('.product-list__item .image img');

        this.selectors.$gallery = $('.product__gallery');
        this.selectors.$tiugallery = $('.tiu_product__gallery');
        this.selectors.$autodonorgallery = $('.autodonor__gallery');
        this.selectors.$items = $('.product__gallery__items');
        this.selectors.$tiuitems = $('.tiu_product__gallery__items');
        this.selectors.$tiuitem = $('.tiu_product__gallery__item');
        this.selectors.$autodonoritems = $('.autodonor__gallery__items');
         
        this.selectors.item = '.product__gallery__item';
        this.selectors.TiuItem = '.tiu_product__gallery__item';
        this.selectors.AutodonorItem = '.autodonor__gallery__item';
        
        this.selectors.$addBtn = $('.product__gallery__add-button');
        this.selectors.$addTiuBtn = $('.tiu_product__gallery__add-button');
        this.selectors.$addAutodonorBtn = $('.autodonor__gallery__add-button');
        this.selectors.$input = this.selectors.$gallery.find('input');
        this.selectors.$tiuinput = this.selectors.$tiugallery.find('input');
        this.selectors.$autodonorinput = this.selectors.$autodonorgallery.find('input');
        this.selectors.deleteBtn = '.product__gallery__delete-button';
        this.selectors.tiuDeleteBtn = '.tiu_product__gallery__delete-button';
        this.selectors.autodonorDeleteBtn = '.autodonor__gallery__delete-button';
        this.selectors.openBtn = '.product__gallery__view-button';
        this.selectors.openTiuBtn = '.tiu_product__gallery__view-button';
        this.selectors.openAutodonorBtn = '.autodonor__gallery__view-button';
        
        this.selectors.tplId = 'product__gallery__item';
        this.selectors.tiuTplId = 'tiu_product__gallery__item';
        this.selectors.autodonorTplId = 'autodonor__gallery__item';

        this.selectors.photoSwipe = document.querySelector('.pswp');
        
        this.selectors.$modalProduct = $('#modalProduct');
    }
 
    initLabels() {
        this.labels = {
            errors: {
                tooManyImages: 'Максимальное количество фото - 20 штук',
                errorFileType: 'Неправильный тип файла',
                errorFileSize: 'Размер файла должен быть не больше 16 Мб',
                uploadError: 'Не удалось загрузить изображение на сервер.',
                assignAllError: 'При добавлении изображений произошла ошибка.' 
            }
        };  
    }
    
    initDraggableGalleries() {
        const sortableSettings = {
            cursor: "move",
            revert: true,
        }
        this.selectors.$tiuitems.sortable(sortableSettings);
        this.selectors.$items.sortable(sortableSettings);
        this.selectors.$autodonoritems.sortable(sortableSettings);
 
        this.selectors.$tiuitems.disableSelection();
        this.selectors.$items.disableSelection();
        this.selectors.$autodonoritems.disableSelection();
    }
 
    listeners() {
        this.selectors.$addBtn.on('click', () => {
            //TODO очистка формы, чтоб можно было те же фотки добавить
            this.selectors.$input.click();
        });

         this.selectors.$addTiuBtn.on('click', () => {
              //TODO очистка формы, чтоб можно было те же фотки добавить
             this.selectors.$tiuinput.click();
         });

         this.selectors.$addAutodonorBtn.on('click', () => {
              //TODO очистка формы, чтоб можно было те же фотки добавить
             this.selectors.$autodonorinput.click();
         });

        this.selectors.$tiuinput.on('change', async (e) => {
            this.app.preloaderFadeIn(this.selectors.$tiuitems);
            let files = e.target.files;
            for (let i = 0; i < files.length; i++) { 
                if (Object.keys(this.data.tiu_images).length >= 20) {
                    M.toast({html: this.labels.errors.tooManyImages});
                    this.app.preloaderFadeOut();
                    return false;
                }
                let file = files[i];
                let imageId = await this.upload(file);
                if (false !== imageId && imageId.hasOwnProperty('id')) {
                    let image = await this.fetchThumbnail(imageId.id, 'smaller');
                    this.addTiu(image);   
                }
            }
            this.app.preloaderFadeOut();
        });  
        
        this.selectors.$autodonorinput.on('change', async (e) => {
            this.app.preloaderFadeIn(this.selectors.$autodonoritems);
            let files = e.target.files;
            for (let i = 0; i < files.length; i++) { 
                if (this.getAddedImagesCount() >= 20) {
                    M.toast({html: this.labels.errors.tooManyImages});
                    this.app.preloaderFadeOut();
                    return false;
                }
                let file = files[i];
                let imageId = await this.upload(file);
                if (false !== imageId && imageId.hasOwnProperty('id')) {
                    let image = await this.fetchThumbnail(imageId.id, 'smaller');
                    this.addAutodonor(image);   
                }
            }
            this.app.preloaderFadeOut();
        });
         
        
        this.selectors.$input.on('change', async (e) => {
            this.app.preloaderFadeIn(this.selectors.$items);
            this.selectors.$modalProduct.append(this.app.preloader);
            let files = e.target.files;
            for (let i = 0; i < files.length; i++) {
                if (Object.keys(this.data.images).length >= 20) {
                    M.toast({html: this.labels.errors.tooManyImages});
                    this.app.preloaderFadeOut();
                    return false;
                }
                let file = files[i];
                let imageId = await this.upload(file);
                if (false !== imageId && imageId.hasOwnProperty('id')) {
                    let image = await this.fetchThumbnail(imageId.id, 'smaller');
                    this.add(image);
                }
            }
            this.app.preloaderFadeOut();
        });

        $(document.body).on('click', this.selectors.deleteBtn, async (e) => {
            if (!confirm('Вы уверены, что хотите удалить изображение для этого товара?')) {
                return;
            }
            let id = $(e.target).closest(this.selectors.item).data('id');
            this.delete(id);
        });
        
         $(document.body).on('click', this.selectors.tiuDeleteBtn, async (e) => {
            if (!confirm('Вы уверены, что хотите удалить изображение для этого товара?')) {
                return;
            }
            let id = $(e.target).closest(this.selectors.TiuItem).data('id');
            this.deleteTiuProductImage(id); 
        });  
         
         $(document.body).on('click', this.selectors.autodonorDeleteBtn, async (e) => {
            if (!confirm('Вы уверены, что хотите удалить изображение для этого автодонора?')) {
                return;
            }
            let id = $(e.target).closest(this.selectors.AutodonorItem).data('id');
            
            this.deleteAutodonorImage(id); 
        });
         

        $(document.body).on('click', this.selectors.imageItemTrigger, async (e) => {
            let productId = e.target.closest('tr').dataset.id;

            let response = await this.fetchImages(productId, 10);

            this.app.responseValidate(response, ['images'], 'Не удалось получить изображения');

            if (response.data.images.length) {

                let images = await this.prepareImagesForGallery(response.data.images);

                this.open(images);

            } else {
                M.toast({html: 'У этого товара нету изображений'});
            } 
        });

        $(document.body).on('click', this.selectors.openBtn, async () => {
            let ids = Object.keys(this.getAll());

            if (!ids.length) {
                M.toast({html: 'У этого товара нету изображений'});
                return;
            }

            let response = await this.fetchSourcesByIds(ids, 10);

            this.app.responseValidate(response, ['images'], 'Не удалось получить изображения');

            if (response.data.images.length) {

                let images = await this.prepareImagesForGallery(response.data.images);

                this.open(images);

            } else {
                M.toast({html: 'У этого товара нету изображений'});
            }
        });

        $(document.body).on('click', this.selectors.openAutodonorBtn, async () => {
            // let ids = Object.keys(this.getAll());
            //
            // if (!ids.length) {
            //     M.toast({html: 'У этого товара нету изображений'});
            //     return;
            // }
            //
            // let response = await this.fetchSourcesByIds(ids, 10);
            //
            // this.app.responseValidate(response, ['images'], 'Не удалось получить изображения');
            //
            // if (response.data.images.length) {
            //
            //     let images = await this.prepareImagesForGallery(response.data.images);
            //
            //     this.open(images);
            //
            // } else {
            //     M.toast({html: 'У этого товара нету изображений'});
            // }
        });

        $(document.body).on('click', this.selectors.openTiuBtn, async () => {
            // let ids = Object.keys(this.getAll());
            //
            // if (!ids.length) {
            //     M.toast({html: 'У этого товара нету изображений'});
            //     return;
            // }
            //
            // let response = await this.fetchSourcesByIds(ids, 10);
            //
            // this.app.responseValidate(response, ['images'], 'Не удалось получить изображения');
            //
            // if (response.data.images.length) {
            //
            //     let images = await this.prepareImagesForGallery(response.data.images);
            //
            //     this.open(images);
            //
            // } else {
            //     M.toast({html: 'У этого товара нету изображений'});
            // }
        });
        
        
    }

    async fetchThumbnail(id, size, fields = ['name']) {
        let data = {
            id: id,
            size: size,
            fields: fields
        };

        let response = await this.app.fetch('gallery', 'getThumbnail', data);
        
        if (this.app.responseValidate(response, ['image'], 'Произошла ошибка при получении изображения')) {
            this.app.preloaderFadeOut();
            return response.data.image;
        }

        return false;
    }
    
    getAddedImagesCount(){
        return document.querySelectorAll(this.selectors.AutodonorItem).length
    }

    async fetchThumbnails(productId, limit = null, sizes = null, fields = ['name']) {
        let data = {
            productId: productId,
            limit: limit || 1,
            sizes: sizes,
            fields: fields
        };

        let response = await this.app.fetch('gallery', 'getThumbnails', data);

        if (this.app.responseValidate(response, ['images'], 'Произошла ошибка при получении изображений')) {
            return response.data.images;
        }

        return false;
    }

    async fetchImages(productId, limit = null) {
        let data = {
            productId: productId,
            limit: limit || 1
        };
        return await this.app.fetch('gallery', 'getSourcesByProductId', data);
    }

    async upload(file) {
        // if(file['size'] > 2097152){
        if(file['size'] > 16777216){
            M.toast({html: this.labels.errors.errorFileSize});
            return false;
        }
 
        let data = new FormData();
         
        data.append('data', JSON.stringify({sizes: {w: 300, h: 300}}));
        data.append('file', file);
        
        let response = await this.app.fetch('gallery', 'upload', data);
         
        if (this.app.responseValidate(response, ['file'], this.labels.uploadError)) {
            return response.data.file;
        }
    }
    
    async assign(id, productId) {
        return await this.app.fetch('gallery', 'assign', {id: id, productId: productId});
    }

    async assignAll(productId) {
        let status = true;

        let ids = Object.keys(this.getAll());

        if (!ids.length) {
            return true;
        }

        for (let id of ids) {
            if (!status) {
                M.toast({html: assignAllError});
                throw new Error(assignAllError);
            }

            let response = await this.assign(id, productId);

            status = status && response.status;
        }

        return true;
    }

    async unassign(id) {
        let response = await this.app.fetch('gallery', 'unassign', {id: id});

        if (this.app.responseValidate(response, [], 'Не удалось открепить фото.')) {
            return true;
        }

        return false;
    }

    async unassignAll(productId) {
        let response = await this.app.fetch('gallery', 'unassignAll', {productId: productId});

        if (this.app.responseValidate(response, [], 'Не удалось отвязать изображения от товара')) {
            return true;
        }

        return false;
    }

    async fetchSourcesByIds(ids, limit = null) {
        let data = {
            ids: ids,
            limit: limit || 1
        };

        return await this.app.fetch('gallery', 'getSourcesByIds', data);
    }

    async fetchSizes(id) {
        return await this.app.fetch('gallery', 'getSizes', {id: id});
    }

    async prepareImagesForGallery(images) {
        let result = [];

        for (let image of images) {
            let response = await this.fetchSizes(image.id);

            if (this.app.responseValidate(response, ['sizes'], 'Не удалось получить размеры изображений')) {

                let sizes = response.data.sizes;

                result.push({
                    src: this.app.getConfig('imagesUrl') + image.name,
                    w: sizes.w,
                    h: sizes.h
                });
            }
        }

        return result;
    }

     prepareTiuImagesForGallery(images) {
        let result = [];
        for (let image of images) {
            let img = new Image();
            img.src = image.src;
            img.onload = function() {
                result.push({
                    src: img.src,
                    w: img.naturalWidth,
                    h: img.naturalHeight
                });
            }
        }
        return result;
    }
    
    add(file) {
        if (Object.keys(this.data.images).length >= 20) {
            M.toast({html: this.labels.errors.tooManyImages});
            this.app.preloaderFadeOut();
            return;
        }

        if (!file.hasOwnProperty('id') || !file.hasOwnProperty('name')) {
            console.error(this.labels.errors.errorFileType);
        }

        this.data.images[file.id] = file.name;
        
        let size = null;
        
        if(file.thumbs.smaller !==null && file.thumbs.hasOwnProperty('smaller')) {
            size = file.thumbs.smaller;
        } else if(file.thumbs.small !==null && file.thumbs.hasOwnProperty('small')){ 
            size = file.thumbs.small;
        } else if(file.thumbs.medium !==null && file.thumbs.hasOwnProperty('medium')){
            size = file.thumbs.medium;
        } else { 
            size = file.thumbs.thumbnail;
        }
        
        let tplData = {
            id: file.id, 
            src: this.app.getConfig('thumbsUrl') + size,
        };

        this.selectors.$items.append(this.app.getTemplate(this.selectors.tplId, tplData));
    }
    
    addTiu(file) {
        if (Object.keys(this.data.tiu_images).length >= 20) {
            M.toast({html: this.labels.errors.tooManyImages});
            this.app.preloaderFadeOut();
            return;
        }
        if (!file.hasOwnProperty('id') || !file.hasOwnProperty('name')) {
            console.error(this.labels.errors.errorFileType);
        }
        let size = null;
        if(file.thumbs.smaller !==null && file.thumbs.hasOwnProperty('smaller')) {
            size = file.thumbs.smaller;
        } else if(file.thumbs.small !==null && file.thumbs.hasOwnProperty('small')){
            size = file.thumbs.small;
        } else if(file.thumbs.medium !==null && file.thumbs.hasOwnProperty('medium')){
            size = file.thumbs.medium;
        } else {
            size = file.thumbs.thumbnail;
        }
        let tplData = {
            id: file.id,
            src: this.app.getConfig('thumbsUrl') + size,
            full: this.app.getConfig('imagesUrl') + file.name,
        };

        console.log(tplData.full)
        
        this.selectors.$tiuitems.append(this.app.getTemplate(this.selectors.tiuTplId, tplData));
    } 
    
    addAutodonor(file) {
        if (this.getAddedImagesCount() >= 20) {
            M.toast({html: this.labels.errors.tooManyImages});
            this.app.preloaderFadeOut();
            return;
        }
        if (!file.hasOwnProperty('id') || !file.hasOwnProperty('name')) {
            console.error(this.labels.errors.errorFileType);
        } 
        
        let size = null;
        if(file.thumbs.smaller !==null && file.thumbs.hasOwnProperty('smaller')) {
            size = file.thumbs.smaller;
        } else if(file.thumbs.small !==null && file.thumbs.hasOwnProperty('small')){
            size = file.thumbs.small;
        } else if(file.thumbs.medium !==null && file.thumbs.hasOwnProperty('medium')){
            size = file.thumbs.medium;
        } else {
            size = file.thumbs.thumbnail;
        }
        let tplData = {
            id: file.id,
            src: this.app.getConfig('thumbsUrl') + size,
            full: this.app.getConfig('imagesUrl') + file.name,
        };
        this.selectors.$autodonoritems.append(this.app.getTemplate(this.selectors.autodonorTplId, tplData));
    }
    
    showAutodonorImage(id, filesArray){
        let tplData = {
            id: id,
            src: filesArray.smaller,
            full: filesArray.full,
        };
        this.selectors.$autodonoritems.append(this.app.getTemplate(this.selectors.autodonorTplId, tplData));
    }
    
    showTiuProductImage(id, filesArray){
        let tplData = {
            id: id,
            src: filesArray.smaller,
            full: filesArray.full,
        };
        this.selectors.$tiuitems.append(this.app.getTemplate(this.selectors.tiuTplId, tplData));
    } 
    
    CleanTiuImagesArray(){
        for (var key in this.data.tiu_images) { 
            delete this.data.tiu_images[key];
        }
    }
    
    async fetchTiuImages(productId, main_image, images) {
        let data = {
            productId: productId,
            mainImage: main_image,
            images: images
        };
        return await this.app.fetch('gallery', 'updateTiuImages', data);
    }

    async fetchAutodonorImages(id, images) {
        let data = {
            autodonorId: id,
            images: images
        };
        return await this.app.fetch('gallery', 'updateAutodonorImages', data);
    }
    
    saveTiuProductImages(){
        let productId = document.querySelector("#modalTiuProductEdit input[name='data[id]']").value;
        let tiuImages = document.querySelectorAll('.tiu_product__gallery__item');
       
        if(tiuImages.length){
            let tiuImagesUpload = [];
            tiuImages.forEach((image, index) => {
                if(index !== 0) tiuImagesUpload.push(image.getAttribute('data-full'));
            });
            
            this.fetchTiuImages(productId, tiuImages[0].getAttribute('data-full'), JSON.stringify(tiuImagesUpload));
        } else {
            this.fetchTiuImages(productId, null, null);
        }
    }

    saveAutodonorImages(autodonorId){
        let images = document.querySelectorAll(this.selectors.AutodonorItem);
        if(images.length){
            let imagesUpload = [];
            images.forEach(image => {
                imagesUpload.push(image.getAttribute('data-full'));
            });
            this.fetchAutodonorImages(autodonorId, JSON.stringify(imagesUpload));
        } else {
            const serie = document.querySelector('#modalAutodonor #autodonor-form__serie');
            if (serie.selectedOptions.length) {
                if (serie.selectedOptions[0].dataset.hasOwnProperty('id')){
                    const serie_id = serie.selectedOptions[0].dataset.id;
                    const image_url = this.app.getConfig('adDefPhotosUrl') + serie_id + '.jpg';
                    this.fetchAutodonorImages(autodonorId, JSON.stringify([image_url])); 
                } else {  
                    this.fetchAutodonorImages(autodonorId, null);
                }
            } else {
                this.fetchAutodonorImages(autodonorId, null);
            }
        }
    }

    
    deleteAutodonorImage(id){
        $(this.selectors.AutodonorItem + `[data-id="${id}"]`).remove();
    }
    
    deleteTiuProductImage(id){
        $(this.selectors.TiuItem + `[data-id="${id}"]`).remove();
    }

    delete(id) {
        delete this.data.images[id];
        $(this.selectors.item + `[data-id="${id}"]`).remove();
    }

    clear() {
        $.each(this.getAll(), (i, v) => {
            this.delete(i);
        });
    }

    getAll() {
        return this.data.images;
    }

    open(images, index) {
        // this.data.photoSwipeOptions.index = index;
        this.data.photoSwipeInstance = new PhotoSwipe(this.selectors.photoSwipe, PhotoSwipeUI_Default, images, this.data.photoSwipeOptions);
        let interval = setInterval(() => {
            if (!this.selectors.photoSwipe.classList.contains('pswp--open')) {
                this.data.photoSwipeInstance.init();
                clearInterval(interval);
            }
        }, 100);
    }
}